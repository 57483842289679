import { API } from 'aws-amplify'
import dayjs from 'dayjs'
import React, { useState, useEffect } from 'react'

export default function CustomerDelight() {
  const [loading, setLoading] = useState(true)
  const [_connected, _setConnected] = useState(false)
  const [_url, _setUrl] = useState(null)
  const [customer, setCustomer] = useState<Customer | null>(null)
  const [dogs, setDogs] = useState<Dog[] | null>(null)
  const [plans, setPlans] = useState<any[]>([])
  const [orders, setOrders] = useState<Order[]>([])
  const [subscriptionId, setSubscriptionId] = useState<string[]>([])
  const [accountSummary, setAccountSummary] = useState<AccountSummary | null>(null)
  const [userId, setUserId] = useState('')
  const [userDataId, setUserDataId] = useState('')
  const [idType, setIdType] = useState('Spoof user ID')
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const [textMacro1, setTextMacro1] = useState<any | null>(null)
  const [textMacro2, setTextMacro2] = useState<any | null>(null)
  const [multipleCustomersFound, setMultipleCustomersFound] = useState(false)
  const [response, setResponse] = useState(null)

  const formattedDate = dayjs().format('DD MMM HH:mm')
  const chargebeeBaseUrl =
    process.env.GATSBY_USER_BRANCH === 'production'
      ? 'https://differentdog.chargebee.com'
      : 'https://differentdog-test.chargebee.com'

  interface Customer {
    customerId: string
    name: string
    surname: string
    email: string
    phone: string
    address: Address
    consent: {
      marketing: boolean
      sms_consent: boolean
      mailConsentFirstParty: boolean
      mailConsentThirdParty: boolean
    }
  }

  interface BillingAddress {
    address1: string
    address2: string
    city: string
    county: string
    postcode: string
  }

  interface Address {
    billing: BillingAddress
  }

  interface Dog {
    id: string
    name: string
    weight: number
    dob: string
    exercise: string
    physique: string
    neutered: boolean
    gender: string
    medicalConditionOrAllergies: boolean
    breed: { value: string; label: string }[]
    kcalPerDay: number
    gramsPerDay: number
    percentageOfDiet: number
    createdAt: string
    updatedAt: string
    subscription: {
      id: string
      dogs: string
      status: string
    }
  }

  interface Order {
    id: string
    invoiceId: string
    subscriptionId: string
    addons: any[]
    address: {
      shipping: any
    }
    delivery: any[]
    discount: {
      code: string
    }
    paymentStatus: string
    plan: string
    recipes: any[]
    shipDate: string
    status: string
    total: number
  }

  interface AccountSummary {
    customer: {
      customerId: string
      name: string
      surname: string
      email: string
      phone: string
      credit: number
      isNewCustomer: boolean
      payment: {
        status: string
        lastFour: string
        expiry: {
          month: number
          year: number
        }
        brand: string
        type: string
      }
    }
    plans: Array<{
      subscriptionId: string
      status: string
      dogNames: string
      plan: string
      nextDelivery: string
      nextPayment: string
      promotionalCreditApplied: number
      nextPlan: object
      address: {
        shipping: {
          name: string
          surname: string
          address1: string
          address2?: string
          city: string
          county: string
          postcode: string
        }
      }
    }>
  }

  // useEffect(() => {
  //   const checkStatus = async () => {
  //     setLoading(true)
  //     const { status, url = null } = await API.get('wwwREST', '/v1/private/admin/xero/status?1=1', {})
  //     console.log({ status, url })
  //     setConnected(status)
  //     if (url) setUrl(url)
  //     setLoading(false)
  //   }

  //   checkStatus().catch(err => console.error(JSON.stringify(err, null, 2)))
  // }, [])

  // const connect = async () => {
  //   const url = await API.get('wwwREST', '/v1/private/admin/customerdelight/', {});
  //   window.open(url, '_blank');
  // };

  const clearAllData = () => {
    setCustomer(null)
    setUserDataId('')
    setDogs(null)
    setPlans([])
    setOrders([])
    setSubscriptionId([])
    setAccountSummary(null)
    setTextMacro1(null)
    setTextMacro2(null)
    setErrorMessage(null)
    setMultipleCustomersFound(false)
  }

  const fetchUserData = async () => {
    try {
      if (!errorMessage && userDataId) {
        const customerResponse = await API.get('wwwREST', `/v1/private/admin/account/customer?userId=${userDataId}`, {})
        if (customerResponse) setCustomer(customerResponse.customer)
        setErrorMessage(null)
      }
    } catch (err) {
      console.error('Error fetching user data', err)
      setErrorMessage('Error fetching user data. Please check your query.')
      if (err) {
        console.error(err)
      } else {
        setErrorMessage('An unknown error occurred')
      }
      setCustomer(null)
    }

    try {
      if (!errorMessage && userDataId) {
        const dogsResponse = await API.get('wwwREST', `/v1/private/admin/account/dogs?userId=${userDataId}`, {})
        if (dogsResponse && Array.isArray(dogsResponse.dogs)) {
          setDogs(dogsResponse.dogs)
          const subscriptionId = dogsResponse.dogs.map((dog: Dog) => dog.subscription.id)
          setSubscriptionId(subscriptionId)
        } else {
          console.error('Unexpected response format for dogs:', dogsResponse)
          setErrorMessage('Unexpected response format for dogs')
        }
      }
    } catch (err) {
      console.error(err)
    }

    try {
      if (!errorMessage && userDataId) {
        const planResponse = await API.get('wwwREST', `/v1/private/admin/account/plans?userId=${userDataId}`, {
          headers: {
            'webpack-compilation-hash': 'spoof'
          }
        })
        if (planResponse) {
          setPlans(planResponse.plans)
        } else {
          console.error('No data in response')
        }
      }
    } catch (err) {
      console.error('Error fetching plan data', err)
    }

    try {
      if (!errorMessage && userDataId) {
        const ordersResponse = await API.get('wwwREST', `/v1/private/admin/account/order/all?userId=${userDataId}`, {
          headers: {
            'webpack-compilation-hash': 'spoof'
          }
        })
        if (ordersResponse && ordersResponse.orders) {
          setOrders(ordersResponse.orders as Order[])
        } else {
          console.error('No order data in response')
        }
      }
    } catch (err) {
      console.error('Error fetching orders', err)
    }

    try {
      if (!errorMessage && userDataId) {
        const accountSummaryResponse = await API.get(
          'wwwREST',
          `/v1/private/admin/account/summary?userId=${userDataId}`,
          {
            headers: {
              'webpack-compilation-hash': 'spoof'
            }
          }
        )
        setAccountSummary(accountSummaryResponse as AccountSummary)
      }
    } catch (err) {
      console.error('Error fetching orders', err)
    }
  }

  const handleSubmit = async () => {
    clearAllData()
    setLoading(true)
    setErrorMessage(null)

    try {
      let response
      switch (idType) {
        case 'Customer ID':
          response = await API.get('wwwREST', `/v1/private/admin/customer/${userId}`, {})
          break
        case 'Subscription ID':
          response = await API.get('wwwREST', `/v1/private/admin/subscription/${userId}`, {})
          break
        case 'Phone':
          response = await API.get('wwwREST', `/v1/private/admin/user/phone/${userId}`, {})
          break
        case 'Email':
          response = await API.get('wwwREST', `/v1/private/admin/user/email/${userId}`, {})
          break
        case 'Spoof user ID':
          setUserDataId(userId)
          return
        default:
          throw new Error('Invalid ID type')
      }

      setResponse(response)

      if (response && response.cognitoUser) {
        setUserDataId(response.cognitoUser)
      } else {
        console.error('No data in response')
        setErrorMessage('No data found. Please check your query.')
      }
    } catch (err) {
      console.error('Error fetching data', err)
      if (idType === 'Phone' && err.response?.data?.error != 'Multiple customers found') {
        clearAllData()
        setErrorMessage('Error fetching user data. Please ensure phone is inserted in +4477777777777 format.')
      } else if (idType === 'Phone' && err.response?.data?.error === 'Multiple customers found') {
        clearAllData()
        setMultipleCustomersFound(true)
        setErrorMessage(`Multiple customers found with this phone number.`)
      } else {
        clearAllData()
        setErrorMessage('Error fetching user data. Please check your query')
      }
    }
    setLoading(false)
  }

  useEffect(() => {
    if (userDataId) {
      fetchUserData()
    }
  }, [userDataId, response, loading])

  const getPlanForDog = (subscriptionId: string, plans: any) => {
    return plans.find(plan => plan.subscriptionId === subscriptionId)
  }

  const handleCopyTextMacro = (text: string) => {
    try {
      navigator.clipboard.writeText(text)
    } catch (err) {
      console.error(err)
    }
  }

  // const cleanPhoneNumber = (phone: string) => {
  //   let cleaned = phone.replace(/\s+/g, '')
  //   if (/^0\d{10}$/.test(cleaned)) {
  //     cleaned = '+44' + cleaned.slice(1)
  //   }
  //   return cleaned
  // }

  const setUserIdValue = e => {
    e.preventDefault()
    let value = e.target.value.replace(/\s/g, '') // Remove all whitespace immediately
    setUserId(value)
  }

  useEffect(() => {
    if (customer) {
      if (dogs && orders && orders.length > 0) {
        const dogNames = dogs?.map(dog => dog.name).join(', ') || 'your dog'
        const dogPronoune = dogs && dogs.length === 1 ? (dogs[0].gender === 'MALE' ? 'his' : 'her') : 'their'

        const recipeNames = orders[0].recipes
          .map(recipe => `${recipe.quantity} x ${recipe.description}`)
          .join(', ')
          .replace(/,(?!.*,)/gim, ' and')

        setTextMacro1([
          `${formattedDate}\n\nHi ${
            customer?.name || ''
          },\n\nThank you so much for getting in touch. We are so pleased to hear that ${dogNames} is enjoying <xxxxxxx> recipe! I have now updated ${dogNames}'s plan for you and ${dogPronoune} next order will contain ${recipeNames}.\n\nBest wishes,\n`
        ])

        const currentHour = dayjs().hour()
        let greeting

        if (currentHour < 12) {
          greeting = 'Good morning'
        } else if (currentHour < 17) {
          greeting = 'Good afternoon'
        } else {
          greeting = 'Good evening'
        }

        const recipeNamesWithNewLines = orders[0].recipes
          .map(recipe => `${recipe.quantity} x ${recipe.description},\n`)
          .join('')

        setTextMacro2([
          `${formattedDate}\n\nTo ${
            (customer?.name || '') + ' ' + (customer?.surname || '')
          },\n\n${greeting},\n\nYes that is correct, if that is ok?\n\nThis order will contain:\n\n${recipeNamesWithNewLines}Please do let me know if this is ok? I have updated the plan to match this too.\n
  Please do let me know if there is anything else I can help you with today.\n
  Best wishes\n`
        ])
      }
    }
  }, [customer, dogs, orders])

  return (
    <div className="m-5 max-w-sm">
      {/* {loading && <p>Loading...</p>}
      {!loading && connected && <p>Xero is connected</p>}
      {!loading && !connected && url && ( */}
      <div className="mb-12" style={{ width: '630px' }}>
        <div className="font-semibold">Fetch customer data:</div>
        <textarea
          className="mt-5 w-full border border-gray-300 p-2 rounded-md"
          rows={1}
          placeholder="Enter id"
          onChange={setUserIdValue}
          value={userId}
        />
        <select
          className="mt-5 w-full border border-gray-300 p-2 rounded-md"
          value={idType}
          onChange={e => setIdType(e.target.value)}
        >
          <option value="Spoof user ID">Spoof user ID</option>
          <option value="Customer ID">Customer ID</option>
          <option value="Subscription ID">Subscription ID</option>
          <option value="Phone">Phone</option>
          <option value="Email">Email</option>
        </select>
        <div>
          <button
            type="button"
            className="mt-5 flex justify-center rounded-md border border-transparent bg-primary py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-base focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            onClick={handleSubmit}
          >
            Submit
          </button>
        </div>
        {errorMessage && <div className="mt-5 p-4 border border-red-500 text-red-500 rounded-md">{errorMessage}</div>}
        {userDataId && customer && !multipleCustomersFound && (
          <div className="mt-5 p-4 border border-gray-300 rounded-md">
            <h3>Spoof link</h3>
            <p>
              <a
                href={`http://localhost:8000/account?userId=${userDataId}`}
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-600 hover:underline"
              >
                Click here to spoof this customer
              </a>
            </p>
          </div>
        )}
        {customer && !multipleCustomersFound && (
          <div className="mt-5 p-4 border border-gray-300 rounded-md">
            <h3>Customer</h3>
            <p>
              <strong>Name:</strong>{' '}
              <a
                href={`${chargebeeBaseUrl}/customers?view_code=all&Customers.search=${encodeURIComponent(
                  `${customer.name} ${customer.surname}`
                )}`}
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-600 hover:underline"
              >
                {customer.name} {customer.surname}
              </a>
            </p>
            <p>
              <strong>New customer:</strong> {accountSummary?.customer.isNewCustomer ? '✓' : '✗'}
            </p>
            <p>
              <strong>Email:</strong> {customer.email}
            </p>
            <p>
              <strong>Phone:</strong> {customer.phone}
            </p>
            <div>
              <strong>Billing address:</strong> {(() => {
                const { address1, address2, city, county, postcode } = customer.address.billing
                return (
                  <div>
                    <p>{address1}</p>
                    {address2 && <p>{address2}</p>}
                    <p>
                      {city}, {county}, {postcode}
                    </p>
                  </div>
                )
              })()}
              {accountSummary && accountSummary.customer && accountSummary?.customer?.payment?.brand && (
                <div>
                  <p>
                    <strong>Payment Details:</strong> Type:{' '}
                    {accountSummary.customer.payment.brand.charAt(0).toUpperCase() +
                      accountSummary.customer.payment.brand.slice(1)}
                    , Last four: {accountSummary.customer.payment.lastFour}, Expiry:{' '}
                    {accountSummary.customer.payment.expiry.month.toString().padStart(2, '0')}/
                    {accountSummary.customer.payment.expiry.year}, Status:{' '}
                    {accountSummary.customer.payment.status.charAt(0).toUpperCase() +
                      accountSummary.customer.payment.status.slice(1)}
                  </p>
                </div>
              )}{' '}
            </div>
            {accountSummary && (
              <p>
                <strong>Customer ID:</strong>
                <span className="ml-1">
                  <a
                    href={`${chargebeeBaseUrl}/d/customers/${accountSummary?.customer.customerId}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-blue-600 hover:underline"
                  >
                    {accountSummary.customer.customerId}
                  </a>
                </span>
              </p>
            )}
            <p>
              <strong>Consent:</strong> marketing: {customer.consent.marketing ? '✓' : '✗'}, sms:{' '}
              {customer.consent.sms_consent ? '✓' : '✗'}, mail: {customer.consent.mailConsentFirstParty ? '✓' : '✗'},
              3rd party mail: {customer.consent.mailConsentThirdParty ? '✓' : '✗'}
            </p>
            <p>
              <strong>Credit: </strong>£{((accountSummary?.customer.credit || 0) / 100).toFixed(2)}
            </p>
          </div>
        )}
        {dogs &&
          dogs.length > 0 &&
          !multipleCustomersFound &&
          dogs.map(dog => (
            <div key={dog.id} className="mt-5 p-4 border border-gray-300 rounded-md">
              <h3>Dogs</h3>
              <p>
                <strong>Name:</strong> {dog.name}
              </p>
              <p>
                <strong>Weight:</strong> {dog.weight}
              </p>
              <p>
                <strong>Date of Birth:</strong> {dog.dob}
              </p>
              <p>
                <strong>Exercise:</strong> {dog.exercise}
              </p>
              <p>
                <strong>Neutered:</strong> {dog.neutered ? 'Yes' : 'No'}
              </p>
              <p>
                <strong>Gender:</strong> {dog.gender}
              </p>
              <p>
                <strong>Medical Condition or Allergies:</strong> {dog.medicalConditionOrAllergies ? 'Yes' : 'No'}
              </p>
              <p>
                <strong>Breed:</strong> {dog.breed.map(b => b.label).join(', ')}
              </p>
              <p>
                <strong>Grams Per Day:</strong> {dog.gramsPerDay}
              </p>
              <p>
                <strong>Percentage of Diet:</strong> {dog.percentageOfDiet}%
              </p>
              <p>
                <strong>Created At:</strong> {new Date(dog.createdAt).toLocaleString()}
              </p>
              <p>
                <strong>Updated At:</strong> {new Date(dog.updatedAt).toLocaleString()}
              </p>
              <div>
                {(() => {
                  const plan = getPlanForDog(dog.subscription.id, plans)
                  if (plan && dog.gramsPerDay) {
                    const packSize = parseInt(plan.plan.split('-')[0])
                    const percentageOfPack = (dog.gramsPerDay / packSize) * 100
                    return (
                      <p>
                        <strong>Feeding guide:</strong> {dog.gramsPerDay}g a day for {dog.name} which is approximately{' '}
                        {percentageOfPack.toFixed(0)}% of a pack.
                      </p>
                    )
                  }
                  return null
                })()}
              </div>
              <p>
                <strong>Subscription ID:</strong>
                <span className="ml-1">
                  <a
                    href={`${chargebeeBaseUrl}/d/subscriptions/${dog.subscription.id}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-blue-600 hover:underline"
                  >
                    {subscriptionId}
                  </a>
                </span>
              </p>
            </div>
          ))}
        {plans &&
          plans.length > 0 &&
          !multipleCustomersFound &&
          plans.map((plan, index) => (
            <div key={index} className="mt-5 p-4 border border-gray-300 rounded-md">
              <h3> {plan.dogNames}'s plan</h3>
              <p>
                <strong>Subscription ID:</strong>
                <span className="ml-1">
                  <a
                    href={`${chargebeeBaseUrl}/d/subscriptions/${subscriptionId}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-blue-600 hover:underline"
                  >
                    {subscriptionId}
                  </a>
                </span>
              </p>
              <p>
                <strong>Status:</strong> {plan.status}
              </p>
              <p>
                <strong>Plan:</strong> {plan.plan}
              </p>
              <p>
                <strong>Quantity:</strong> {plan.quantity}
              </p>
              <p>
                <strong>Weeks:</strong> {plan.weeks}
              </p>
              <p>
                <strong>Next Delivery:</strong> {plan.nextDelivery}
              </p>
              <p>
                <strong>Next Payment:</strong> {plan.nextPayment}
              </p>
              <p>
                <strong>Price:</strong> £{(plan.price / 100).toFixed(2)}
              </p>
              <p>
                <strong>Is In Trial:</strong> {plan.isInTrial ? 'Yes' : 'No'}
              </p>
              {plan.nextPlan && plan.nextPlan.plan !== null && (
                <div>
                  <strong>Next Plan:</strong> {plan.nextPlan.plan} Quantity: {plan.nextPlan.quantity} Weeks:{' '}
                  {plan.nextPlan.weeks}
                </div>
              )}
              <p>
                <strong>Dog Names:</strong> {plan.dogNames}
              </p>
              <p>
                <strong>Packs Per Day:</strong> {plan.packsPerDay}
              </p>
              <p>
                <strong>Plan Type:</strong> {plan.planType}
              </p>
              {plan.coupons && plan.coupons.length > 0 && (
                <p>
                  <strong>Coupons:</strong> {plan.coupons.join(', ')}
                </p>
              )}
              {plan.recipes && plan.recipes.length > 0 && (
                <div>
                  <strong>Recipes:</strong>{' '}
                  {plan.recipes.map((recipe: any, recipeIndex: number) => (
                    <React.Fragment key={recipeIndex}>
                      {recipeIndex > 0 && ', '}
                      {recipe.name} ({recipe.quantity})
                    </React.Fragment>
                  ))}
                </div>
              )}
              {plan.addons && plan.addons.length > 0 ? (
                <div>
                  <strong>Addons:</strong>{' '}
                  {plan.addons.map((addon: any, addonIndex: number) => (
                    <React.Fragment key={addonIndex}>
                      {addonIndex > 0 && ', '}
                      {addon.name} ({addon.quantity})
                    </React.Fragment>
                  ))}
                </div>
              ) : (
                <div>
                  <strong>Addons:</strong> None
                </div>
              )}
              {plan.address && plan.address.shipping && (
                <div>
                  <strong>Shipping Address:</strong>
                  <p>
                    {plan.address.shipping.name} {plan.address.shipping.surname}
                  </p>
                  <p>{plan.address.shipping.address1}</p>
                  {plan.address.shipping.address2 && <p>{plan.address.shipping.address2}</p>}
                  <p>
                    {plan.address.shipping.city}, {plan.address.shipping.county}
                  </p>
                  <p>{plan.address.shipping.postcode}</p>
                </div>
              )}
              <p>
                <strong>Promotional Credit: </strong>£{(plan.promotionalCreditsApplied / 100).toFixed(2)}
              </p>
            </div>
          ))}
        {orders.length > 0 && !multipleCustomersFound ? (
          <div className="mt-5 p-4 border border-gray-300 rounded-md">
            <h3 className="mb-4 text-2xl">Orders</h3>
            {orders.map((order: Order) => (
              <div key={order.id} className="mb-2 flex flex-row justify-between">
                <p className="flex flex-wrap">
                  <span className="mr-4">
                    <strong>Order no: </strong>
                    <span className="ml-2">
                      <a
                        href={`${chargebeeBaseUrl}/d/orders/${order.id}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-blue-600 hover:underline"
                      >
                        {order.id}
                      </a>
                    </span>
                  </span>
                  <span className="mr-4">
                    <strong>Invoice ID: </strong>
                    <span className="ml-2">
                      <a
                        href={`${chargebeeBaseUrl}/d/invoices/${order.invoiceId}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-blue-600 hover:underline"
                      >
                        {order.invoiceId}
                      </a>
                    </span>
                  </span>
                  <span className="mr-4">
                    <strong>Shipstation: </strong>
                    <span className="ml-2">
                      <a
                        href={`https://ship11.shipstation.com/orders/all-orders-search-result?quicksearch=CB${order.id}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-blue-600 hover:underline"
                      >
                        {'Click here'}
                      </a>
                    </span>
                  </span>
                  <span className="mr-4">
                    <strong>Status: </strong> <span className="ml-2">{order.status}</span>
                  </span>
                  {order.discount.code && (
                    <span className="mr-4">
                      <strong>Discount: </strong> <span className="ml-2">{order.discount.code}</span>
                    </span>
                  )}
                  <span className="mr-4">
                    <strong>Ship Date: </strong>{' '}
                    <span className="ml-4">{dayjs(order.shipDate).format('DD/MM/YYYY')}</span>
                  </span>
                  <span>
                    <strong>Total: </strong> <span className="ml-4">£{(order.total / 100).toFixed(2)}</span>
                  </span>
                </p>
              </div>
            ))}
          </div>
        ) : (
          <>
            {textMacro1 && !multipleCustomersFound && (
              <div className="mt-5 p-4 border border-gray-300 rounded-md">
                <h3 className="mb-4 text-2xl">Orders</h3>
                <p>No orders at present</p>
              </div>
            )}
          </>
        )}
        <>
          {textMacro1 && !multipleCustomersFound && (
            <div className="mt-5 p-4 border border-gray-300 rounded-md">
              <div>
                <h2>Text Macros</h2>
                <label htmlFor="textMacro1" style={{ fontSize: '17px' }}>
                  Text Macro 1
                </label>
                <textarea
                  id="textMacro1"
                  value={textMacro1}
                  readOnly
                  style={{
                    width: '100%',
                    height: '90px',
                    resize: 'vertical'
                  }}
                />
                <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
                  <button
                    style={{ marginTop: '5px', marginLeft: 'auto' }}
                    type="button"
                    className="mt-5 flex justify-center rounded-md border border-transparent bg-primary py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-base focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    onClick={() => handleCopyTextMacro(textMacro1)}
                  >
                    Copy
                  </button>{' '}
                </div>
              </div>
              <div style={{ marginTop: '30px' }}>
                <label htmlFor="textMacro2" style={{ fontSize: '17px', marginTop: '20px' }}>
                  Text Macro 2
                </label>
                <textarea
                  id="textMacro2"
                  value={textMacro2}
                  readOnly
                  style={{
                    width: '100%',
                    height: '90px',
                    resize: 'vertical'
                  }}
                />
                <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
                  <button
                    style={{ marginTop: '5px' }}
                    type="button"
                    className="mt-5 flex justify-center rounded-md border border-transparent bg-primary py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-base focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    onClick={() => handleCopyTextMacro(textMacro2)}
                  >
                    Copy
                  </button>
                </div>
              </div>
            </div>
          )}{' '}
        </>
      </div>
    </div>
  )
}
